.navbar {
  &.app-header {
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: $navbar-nav-link-padding-y $navbar-nav-link-padding-x;
          position: relative;
          overflow: hidden;

          &::before {
            content: '';
            position: absolute;
            bottom: -.125rem;
            height: .125rem;
            left: 0;
            right: 0;
            background: $red;
          }

          &.active {
            &::before {
              bottom: 0;
            }
          }

          @include media-breakpoint-down(md) {
            padding: 1rem ($grid-gutter-width * 0.5);
            margin: 0 (-$grid-gutter-width * 0.5);

            &::before {
              left: -.125rem;
              right: auto;
              top: 0;
              bottom: 0;
              height: auto;
              width: .125rem;
            }

            &.active {
              &::before {
                left: 0;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: 1rem 0;

      > .container {
        padding-left: $grid-gutter-width * 0.5;
        padding-right: $grid-gutter-width * 0.5;
      }
    }
  }
}