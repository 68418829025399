@font-face {
  font-family: 'TT Norms';
  src: url('/fonts/TTNorms-Medium.woff2') format('woff2'),
      url('/fonts/TTNorms-Medium.woff') format('woff'),
      url('/fonts/TTNorms-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: url('/fonts/TTNorms-Regular.woff2') format('woff2'),
      url('/fonts/TTNorms-Regular.woff') format('woff'),
      url('/fonts/TTNorms-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}