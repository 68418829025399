@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$body-bg: #f6f8f7;
$font-family-sans-serif: 'TT Norms';
$font-family-base: $font-family-sans-serif;

$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-y: 1.75rem;
$navbar-nav-link-padding-x: .75rem;

$cards-per-line: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 3
);
$card-border-radius: .15rem;