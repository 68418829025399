@import 'includes/fonts';
@import 'includes/variables';
@import 'includes/navbar';
@import '~bootstrap/scss/bootstrap.scss';

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .app-root {
    flex: 1;
  }
}

body, html {
  scroll-behavior: smooth;
  font-variant-ligatures: none;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

.app {
  display: flex;
  min-height: 100vh;
  margin: 0 auto;
  flex-direction: column;
  overflow: hidden;

  .app-content {
    flex: 1 1;
  }

  .app-header {
    box-shadow: -.125rem 0 .5rem hsla(0,0%,89.4%,.38);
    background: #FFF;
  }

  .app-footer {
    margin-top: 2rem;
    padding: 2rem 0;
    background: darken($body-bg, 3%);

    .app-footer-contacts {
      text-align: right;

      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
  }
}

.page-header {
  padding: 2rem 0;
}

.page-image {
  padding: 10rem 0 15rem;

  &.page-image-dpils {
    background-image: url(/images/jpg/dpils.jpg);
    //background-attachment: fixed;
    background-size: cover;
    background-position: 100% 50%;
    text-shadow: .125rem .125rem .125rem rgba(#000, .8);
    margin-bottom: 3rem;

    h1 {
      font-size: 7.5rem;
      color: #FFF;
      font-weight: bold;
      line-height: 1;
    }

    .page-image-description {
      color: #FFF;
      font-size: 1.5rem;
    }

    @include media-breakpoint-down(md) {

      padding: 5rem 0 7.5rem;
      
      h1 {
        font-size: 4rem;
      }

      .page-image-description {
        font-size: 1.2rem;
      }
    }
  }
}

.page-content {
  font-size: $font-size-base;
  
  .qr-code {
    margin-left: -1rem;

    &, svg {
      width: 30rem;
      height: 30rem;

      @include media-breakpoint-down(md) {
        width: 20rem;
        height: 20rem;
      }
    }
  }

  .page-cards {
    .card {
      margin-bottom: $grid-gutter-width; 
      box-shadow: -.125rem 0 .5rem hsla(0, 0%, 89.4%, .78);

      &.card-yt {
        .card-header {
          background: #FF0000;
        }
      }
      
      &.card-tg {
        .card-header {
          background: #0088cc;
        }
      }

      &.card-fb {
        .card-header {
          background: #3B5998;
        }
      }

      &.card-d2 {
        .card-header {
          background: #c03;
        }
      }

      &.card-ig {
        .card-header {
          background: linear-gradient(to right, #405de6, #405de6 20%, #5851db 40%, #833ab4 60%, #c13584 80%, #e1306c 100%);
        }
      }

      .card-header {
        color: #FFF;
        line-height: 1;

        .svg-icon {
          margin-right: .5rem;

          &, svg {
            width: 1em;
            height: 1em;
            
            .fill {
              fill: #FFF;
            }
          }
        }
      }

      .card-title {
        font-weight: 500;
      }

      .card-text {
        line-height: 1.1;
        padding-bottom: 1.5rem;
      }
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue';
}

.svg-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: inherit;
  
  &, svg {
    width: 1rem;
    height: 1rem;
  }
}

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
      .card-deck .card {
          flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
      }
  }
}